import React from 'react';

const FallbackComponent = ({errorMessage}) => {
    return (
        <div className='flex justify-center items-center h-screen w-full'>
            <svg
              width="137"
              height="128"
              viewBox="0 0 137 128"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M122.85 55.05C122.85 69.25 117.05 82.05 107.75 91.25C98.65 100.35 86.0499 105.85 72.15 105.85C58.35 105.85 45.75 100.25 36.5499 91.25C27.2499 82.05 21.45 69.25 21.45 55.05C21.45 26.95 44.15 4.25 72.15 4.25C100.15 4.25 122.85 27.05 122.85 55.05Z"
                fill="#EAEEF9"
              />
              <path
                d="M118.45 21.75C120.714 21.75 122.55 19.9144 122.55 17.65C122.55 15.3856 120.714 13.55 118.45 13.55C116.186 13.55 114.35 15.3856 114.35 17.65C114.35 19.9144 116.186 21.75 118.45 21.75Z"
                fill="#EAEEF9"
              />
              <path
                d="M124.45 5.75002C125.996 5.75002 127.25 4.49642 127.25 2.95002C127.25 1.40363 125.996 0.150024 124.45 0.150024C122.904 0.150024 121.65 1.40363 121.65 2.95002C121.65 4.49642 122.904 5.75002 124.45 5.75002Z"
                fill="#EAEEF9"
              />
              <path
                d="M23.05 21.65C24.5964 21.65 25.85 20.3964 25.85 18.85C25.85 17.3036 24.5964 16.05 23.05 16.05C21.5036 16.05 20.25 17.3036 20.25 18.85C20.25 20.3964 21.5036 21.65 23.05 21.65Z"
                fill="#EAEEF9"
              />
              <path
                d="M5.95 75.65C8.82188 75.65 11.15 73.3219 11.15 70.45C11.15 67.5782 8.82188 65.25 5.95 65.25C3.07812 65.25 0.75 67.5782 0.75 70.45C0.75 73.3219 3.07812 75.65 5.95 75.65Z"
                fill="#EAEEF9"
              />
              <g filter="url(#filter0_d)">
                <path
                  d="M112.999 31.4438C111.631 29.5302 109.579 29.7003 108.852 32.0392C107.227 37.2271 102.14 39.1407 99.0192 39.6085C97.2237 26.2984 85.7664 16.05 71.915 16.05C58.1064 16.05 46.6919 26.2559 44.8536 39.4809C41.69 38.8856 37.0729 36.9294 35.5338 31.9966C34.8071 29.6578 32.755 29.5302 31.387 31.4013C28.6082 35.2285 29.7624 40.1188 31.4725 44.116C33.9948 50.197 38.8684 54.5345 44.5971 57.4686C44.4688 64.6552 43.9558 70.5236 41.9465 78.0929C41.2625 80.6869 40.664 82.8131 40.1082 84.2164C39.7234 85.1944 39.4669 86.1725 40.0655 87.1505C41.0915 88.809 43.1008 89.3193 44.9391 89.617C46.6491 89.9146 48.1027 90.0422 49.2997 90.0847C52.2068 90.1698 53.4893 89.7445 53.4893 89.7445C53.4893 89.7445 53.7031 90.0847 54.2588 90.5525C55.8406 91.9558 60.244 94.6773 70.1622 94.2946C82.5173 93.8269 87.2627 92.6362 89.443 90.7651C89.614 90.595 89.785 90.4674 89.9132 90.2973C91.2813 90.2973 92.5211 90.3399 93.6326 90.3399C97.5229 90.3824 100.302 90.4674 102.525 89.6595C104.278 89.0216 105.389 86.9804 104.278 84.8117C100.943 78.263 99.233 71.0339 99.233 63.6772V57.7238C105.175 54.7896 110.306 50.4096 112.913 44.116C114.623 40.1188 115.778 35.2285 112.999 31.4438Z"
                  fill="url(#paint0_linear)"
                />
              </g>
              <path
                d="M93.6748 90.2547C92.5206 90.2547 91.2808 90.2122 89.9555 90.2122C89.8272 90.3823 89.6562 90.5524 89.4852 90.68C88.2455 85.1943 87.1767 79.5812 86.9202 72.6922C88.673 78.6031 90.896 85.5771 93.6748 90.2547Z"
                fill="#DFE0E2"
              />
              <path
                d="M54.2583 90.5099C53.7026 90.0421 53.4888 89.7019 53.4888 89.7019C53.4888 89.7019 52.2063 90.1697 49.2992 90.0421C51.736 85.5771 53.9163 77.1998 53.6598 72.0119C54.0446 81.1546 54.1728 86.8953 54.2583 90.5099Z"
                fill="#DFE0E2"
              />
              <path
                d="M61.7824 47.0076C63.813 47.0076 65.459 45.3703 65.459 43.3505C65.459 41.3308 63.813 39.6934 61.7824 39.6934C59.7519 39.6934 58.1058 41.3308 58.1058 43.3505C58.1058 45.3703 59.7519 47.0076 61.7824 47.0076Z"
                fill="#9CA4B5"
              />
              <path
                d="M80.1655 48.9212C83.2585 48.9212 85.7659 46.4271 85.7659 43.3505C85.7659 40.274 83.2585 37.7799 80.1655 37.7799C77.0724 37.7799 74.5651 40.274 74.5651 43.3505C74.5651 46.4271 77.0724 48.9212 80.1655 48.9212Z"
                fill="#9CA4B5"
              />
              <path
                d="M81.619 54.0666C81.619 55.4699 76.7453 56.618 70.7602 56.618C64.7323 56.618 59.8586 55.4699 59.8586 54.0666H81.619Z"
                fill="#9CA4B5"
              />
              <path
                d="M73.2825 66.3136C70.5037 66.3136 68.2806 64.0598 68.2806 61.3383V55.3849H78.3272V61.3383C78.3272 64.0598 76.0613 66.3136 73.2825 66.3136Z"
                fill="#9CA4B5"
              />
              <path
                opacity="0.2"
                d="M71.9145 105.563C87.9935 105.563 101.028 103.05 101.028 99.9502C101.028 96.8502 87.9935 94.337 71.9145 94.337C55.8356 94.337 42.801 96.8502 42.801 99.9502C42.801 103.05 55.8356 105.563 71.9145 105.563Z"
                fill="#A0A9BA"
              />
              <defs>
                <filter
                  id="filter0_d"
                  x="7.71533"
                  y="5.05005"
                  width="128.955"
                  height="122.281"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="11" />
                  <feGaussianBlur stdDeviation="11" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear"
                  x1="72.1652"
                  y1="14.2393"
                  x2="72.1652"
                  y2="95.175"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FDFEFF" />
                  <stop offset="0.9964" stopColor="#ECF0F5" />
                </linearGradient>
              </defs>
            </svg>
            <h1 className="my-3 font-d-mansans-regular text-[28px] leading-[42px] text-gray-900_01 w-full">Whoops! Something went Wrong!</h1>
            {/* <p>{'looks like we were not able to connect to your server. Please try again later')}</p> */}
            <p>{`${errorMessage}`}</p>
            <button
              className="btn mx-2 py-2 px-4 bg-blue-A400 text-white-A700 rounded-md flex items-center justify-center font-dm-sans-medium text-base leading-6"
              onClick={(e) => window.location.reload(true)}
            >
            Try again
            </button>
        </div>
    );
}

export default FallbackComponent;
